import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Button from "../components/button"
import SEO from "../components/seo"
import Img from "gatsby-image"

const IndexPage = () => {
  
  const { shelfImage, doorImage, wallImage } = useStaticQuery(graphql`
    query {
      shelfImage: file(relativePath: { eq: "compact-hangframe.png" }) {
        childImageSharp {
          fluid(maxWidth: 1536) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },

      doorImage: file(relativePath: { eq: "hangframe.png" }) {
        childImageSharp {
          fluid(maxWidth: 1536) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },

      wallImage: file(relativePath: { eq: "wall.png" }) {
        childImageSharp {
          fluid(maxWidth: 1536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  
  
  return (
    <Layout>
      <SEO />
      <div className="lg:container mx-auto">
        <Img durationFadeIn="3000" className="my-8" fluid={shelfImage.childImageSharp.fluid} />
      </div>


      <div className="container mx-auto my-16 px-2 text-center">
        <h2 className="font-medium text-3xl md:text-5xl">Don't miss the launch</h2>
        <p className="my-4">Sign up to keep up-to-date with development and be the first to know when Hangframe launches</p>
        <Button />
      </div>

      <div className="lg:container mx-auto">
        <Img durationFadeIn="3000" className="my-8" fluid={doorImage.childImageSharp.fluid} />
      </div>



      <div className="sm:container relative max-w-xs mx-auto my-8 sm:text-2xl md:text-4xl">
        <Img className="mx-auto" fluid={wallImage.childImageSharp.fluid} />
        <div className="absolute text-right top-0 right-0 mr-12 mt-1 sm:mr-20 md:mr-24 lg:mr-32 xl:mr-40 xl:mt-8 2xl:mt-14 2xl:mr-44">Vary the width with <br />two separate handholds</div>
        <div className="absolute top-0 left-0 ml-4 mt-20 sm:mt-48 xl:mt-64 2xl:mt-72">Attach to your <br /> doorframe with no <br />permanent fixings</div>
        <div className="absolute bottom-0 left-0 ml-4 mb-32 sm:mb-64 md:text-right md:right-0 md:mr-56 md:mb-80 lg:mr-64 lg:mb-112 2xl:bottom-1/4">Seven holds from beginner to <br />pro, including a pinch</div>
        <div className="absolute text-right bottom-0 right-0 mr-4 mb-10 sm:mb-16 md:mb-24 xl:mb-40">Made from sustainable, <br />eco-friendly materials</div>
      </div>

      <div className="container mx-auto my-8 px-2 text-center">
        <h2 className="font-medium text-3xl md:text-5xl mb-4">Don't miss the launch</h2>
        <Button />
      </div>

      <p className="text-center mb-4">
        Follow us on <a className="underline" target="_blank" rel="noreferrer" href="https://www.instagram.com/hangframe">Instagram</a>
      </p>
    </Layout>
  )
}




export default IndexPage
