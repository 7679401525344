import React from "react"

const Button = () => {

  return (
    <a className="bg-orange px-12 py-2 rounded-sm text-2xl inline-block" href="http://eepurl.com/g8Jyev" target="_blank" rel="noreferrer">Sign up</a>
  )
}

export default Button
